/* eslint-disable no-unused-expressions */
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import('./src/styles.scss')

export const onRouteUpdate = ({ location }) => {
  captureGclid(location.search)
}

const captureGclid = search => {
  const params = new URLSearchParams(search)
  const gclid = params.get('gclid')
  if (gclid) {
    sessionStorage.setItem('gclid', gclid)
  }
}
